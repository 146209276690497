import axios from 'axios';

const BASE_URL = 'https://api.activitesjoueclub-printemps.fr/api'; 

const api = axios.create({
  baseURL: BASE_URL,
  'Content-Type': 'multipart/form-data',

 
});
export const getAllPartenaires = async () => {
    try {
      const response = await api.get('/get-all-partenaires');
      return response.data;
    } catch (error) {
      console.error('Error fetching all partenaires:', error);
      throw error;
    }
  };
  export const getPartenairesByRegion = async (region) => {
    try {
      const response = await api.get(`/get-partenaires-by-region/${region}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching partenaires by region:', error);
      throw error;
    }
  };
  export default {getAllPartenaires,getPartenairesByRegion};
